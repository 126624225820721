import { useState } from 'react';
import { DiRequirejs } from 'react-icons/di';
import { Link, useNavigate } from "react-router-dom";

import './Shopping.scss'
const Shoppingcart = () => {
  const [visible, setVisible] = useState(true);

  const removeElement = () => {
    setVisible((prev) => !prev);
  };
 
    const navigate = useNavigate();
  return (
    <div>
        <div class="wrap cf  fontss">
          <div>
          <div>
            <h1 style={{textAlign:'center'}}>Shopping Cart</h1>
            <img src="" alt="" />
            <hr />
          </div>
          </div>
          <div  className='yourcart'>
          <div>
            <h1>Your Cart</h1>
          </div>
          <div>
    <div><button  onClick={()=> navigate('/products')} class="button-18" role="button">Continue Shopping</button></div>

          </div>
          </div>
          <hr />
  <div class="cart">
{/* <!--    <ul class="tableHead">
      <li class="prodHeader">Product</li>
      <li>Quantity</li>
      <li>Total</li>
       <li>Remove</li>
    </ul>--> */}
    <ul class="cartWrap" style={{padding:'0'}}>
      <li class="items odd">
        
    <div class="infoWrap"> 
        <div class="cartSection">
        <img src={require('../coffeeflavour/irish.png')}  alt="" class="itemImg" />
          <p class="itemNumber">#QUE-007544-002</p>
          <h3>Item Name 1</h3>
        
           <p> <input type="text"  class="qty" placeholder="3"/> x $5.00</p>
        
          <p class="stockStatus"> In Stock</p>
        </div>  
    
        
        <div class="prodTotal cartSection">
          <p>$15.00</p>
        </div>
              <div class="cartSection removeWrap">
           <a href="#" class="remove">x</a>
        </div>
      </div>
      </li>
      <li class="items even">
        
       <div class="infoWrap"> 
        <div class="cartSection">
         
        <img src={require('../coffeeflavour/raspberry.png')}  alt="" class="itemImg" />
          <p class="itemNumber">#QUE-007544-002</p>
          <h3>Item Name 1</h3>
        
           <p> <input type="text"  class="qty" placeholder="3"/> x $5.00</p>
        
          <p class="stockStatus"> In Stock</p>
        </div>  
    
        
        <div class="prodTotal cartSection">
          <p>$15.00</p>
        </div>
              <div class="cartSection removeWrap">
           <a href="#" class="remove">x</a>
        </div>
      </div>
      </li>
      
            <li class="items odd">
             <div class="infoWrap"> 
        <div class="cartSection">
            
        <img src={require('../coffeeflavour/espresso.png')}  alt="" class="itemImg" />
          <p class="itemNumber">#QUE-007544-002</p>
          <h3>Item Name 1</h3>
        
           <p> <input type="text"  class="qty" placeholder="3"/> x $5.00</p>
        
          <p class="stockStatus out"> Out of Stock</p>
        </div>  
    
        
        <div class="prodTotal cartSection">
          <p>$15.00</p>
        </div>
                    <div class="cartSection removeWrap">
           <a href="#" class="remove">x</a>
        </div>
              </div>
      </li>
      <li class="items even">
       <div class="infoWrap"> 
        <div class="cartSection info">
             
        <img src={require('../coffeeflavour/vanilla.png')} class="itemImg" />
          <p class="itemNumber">#QUE-007544-002</p>
          <h3>Item Name 1</h3>
        
          <p> <input type="text"  class="qty" placeholder="3"/> x $5.00</p>
        
          <p class="stockStatus"> In Stock</p>
          
        </div>  
    
        
        <div class="prodTotal cartSection">
          <p>$16.00</p>
        </div>
    
            <div  class="cartSection removeWrap">
            {visible && (
<a  className='remove' onClick={removeElement}>x</a>
)}
        </div>
         </div>
         
         <div class="special"><div class="specialContent">Free gift with purchase!, gift wrap, etc!!</div></div>
      </li>
      
      
      {/* <!--<li class="items even">Item 2</li>--> */}
 
    </ul>
  </div>
  
  <div class="promoCode"><label for="promo">Have A Promo Code?</label>
  <div className='inpu'>

  <input type="text" name="promo" placholder="Enter Code" />
  <button  onClick={()=> navigate('/products')} class="button-18" role="button">apply</button>
  </div>
  </div>
  
  <div class="subtotal cf">
    <ul style={{listStyle:'none'}}>
      <li class="totalRow"><span class="label">Subtotal</span><span class="value">$35.00</span></li>
      
          <li class="totalRow"><span class="label">Shipping</span><span class="value">$5.00</span></li>
      
            <li class="totalRow"><span class="label">Tax</span><span class="value">$4.00</span></li>
            <li class="totalRow final"><span class="label">Total</span><span class="value">$44.00</span></li>
      <li class="totalRow">
      <div><button  onClick={()=> navigate('/check')} class="button-18" role="button">Checkout</button></div></li>
    </ul>
  </div>
</div>


    </div>
  )
}

export default Shoppingcart