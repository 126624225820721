import React from 'react'
import { Link, useNavigate } from "react-router-dom";
const Card4 = () => {
  const navigate = useNavigate();
  return (
    <div className='fontss'>
                <div className="card card4">

<div className="imgBox">
  <img src={require('../../coffeeflavour/raspberry.png')} alt="mouse corsair"
   className="mouse"/>
</div>

<div className="contentBox">
  <h3>RASPBERRY</h3>
  <h2 className="price">61.<small>98</small> €</h2>
 
  <a
   data-aos="zoom-in-up" 
    className="buy"
  
  onClick={() => navigate("/Raspberry")}
  style={{backgroundColor:'#554750'}}
  
  >Buy Now</a>
</div>

</div>
    </div>
  )
}

export default Card4