import React from 'react'
import Navbar from './Navbar'
import './About2.css'
import Footer from './Footer'
import Navbar2 from './Navbar2'
const About = () => {

  return (
    <div className='fontss '>
        <div className='nav22' >
        </div>
      <section className='back-about'>
          <Navbar/>
      
      </section>
      <section className='section2'>
        <div>
        <h1 data-aos="zoom-in-up"  data-aos-duration="1000" className='heading1'>About us</h1>
        </div>
      </section>
      <div className='media-qq'>

      <section>
        <div className='section3'>

        <div  data-aos="fade-right"
        data-aos-duration="1000"
         className='image-div'>
        <img src={require('../img2/cooling-table.jpg')}  />
        </div>
        <div  data-aos="fade-left"
        data-aos-duration="1000"
        className='textsss'>
          <h1 className='heading-2'>Roast Coffee</h1>
          <p className='about-para'>Roasted-on-demand specialty coffee made using best-in-class techniques. High-quality beans sourced from the finest estates across the country.</p>
        </div>
        </div>
      </section>
      <section>
        <div className='section4'>

    
        <div
         data-aos="fade-right"
         data-aos-duration="1000"
         className='textsss'>
          <h1 className='heading-2'>Roast Coffee</h1>
          <p className='about-para'>Roasted-on-demand specialty coffee made using best-in-class techniques. High-quality beans sourced from the finest estates across the country.</p>
        </div>
        <div  
        
        data-aos="fade-left"
        data-aos-duration="1000"
        className='image-div'>
        <img src={require('../img2/instant-coffee-1.jpg')}/>
        </div>
        </div>
      </section>
      <section>
        <div className='section5'>

        <div 
              data-aos="fade-left"
              data-aos-duration="1000"
              className='image-div'>
        <img src={require('../img2/custom-coffee.jpg')}  />
        </div>
        <div 
              data-aos="fade-right"
              data-aos-duration="1000"
              className='textsss'>
          <h1 className='heading-2'>Roast Coffee</h1>
          <p className='about-para'>Roasted-on-demand specialty coffee made using best-in-class techniques. High-quality beans sourced from the finest estates across the country.</p>
        </div>
        </div>
      </section>
          </div>
 {/* mediaQuery */}
 <div className='media-qu'>

      <section>
        <div className='section3'>

        <div  data-aos="fade-up"
        data-aos-duration="1000"
         className='image-div'>
        <img src={require('../img2/cooling-table.jpg')}  />
        </div>
        <div  data-aos="fade-left"
        data-aos-duration="1000"
        className='textsss'>
          <h1 className='heading-2'>Roast Coffee</h1>
          <p className='about-para'>Roasted-on-demand specialty coffee made using best-in-class techniques. High-quality beans sourced from the finest estates across the country.</p>
        </div>
        </div>
      </section>
      <section>
        <div className='section4'>

    
        <div
         data-aos="fade-right"
         data-aos-duration="1000"
        className='textsss'>
          <h1 className='heading-2'>Roast Coffee</h1>
          <p className='about-para'>Roasted-on-demand specialty coffee made using best-in-class techniques. High-quality beans sourced from the finest estates across the country.</p>
        </div>
        <div  
        
         data-aos="fade-up"
         data-aos-duration="1000"
         className='image-div'>
        <img src={require('../img2/instant-coffee-1.jpg')}  />
        </div>
        </div>
      </section>
      <section>
        <div className='section5'>

        <div 
              data-aos="fade-up"
              data-aos-duration="1000"
              className='image-div'>
        <img src={require('../img2/custom-coffee.jpg')}  />
        </div>
        <div 
              data-aos="fade-left"
              data-aos-duration="1000"
              className='textsss'>
          <h1 className='heading-2'>Roast Coffee</h1>
          <p className='about-para'>Roasted-on-demand specialty coffee made using best-in-class techniques. High-quality beans sourced from the finest estates across the country.</p>
        </div>
        </div>
      </section>
          </div>
      <section>
        <Footer/>
      </section>
    </div>
  )
}

export default About